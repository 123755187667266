import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Section from "../components/Section";
import Img from "../components/Img";
import Article from "../components/Article";
import Nav from "../components/Nav";
import Footnote from "../components/Footnote";
import H1 from "../components/H1";
import H2 from "../components/H2";
import Paragraph from "../components/Paragraph";
import ParagraphPull from "../components/ParagraphPull";

const NorthportPage = ({ data }) => (
  <Layout>
    <SEO title="Northport" />
    <Nav />
    <Section>
      <Article isCentered>
        <H1>Northport</H1>
        <Paragraph isLarge>
          Oak Hall was built in the twilight of of Northport’s Gilded Age by a man who would go on
          to establish the legal foundation for the modern summer colony of Bayside.
        </Paragraph>
      </Article>
    </Section>
    <Section>
      <Img
        imgSrc={data.northportDocks.childImageSharp.fluid}
        imgAlt="Postcard: Northport Docks, Maine"
      />
    </Section>
    <Section>
      <Article>
        <Paragraph>
          Northport, in Waldo County, Maine lies on the west side of Penobscot Bay, and adjoins
          Belfast on the south and Lincolnville on the south-west. Northport is home to several
          small enclaves — among them Bayside, Saturday Cove, and Temple Heights
        </Paragraph>
        <Paragraph>
          The town was incorporated in 1796, and within 100 years it had become a favorite watering
          hole for the steamer crowd up from Portland, Boston and other points further south. During the
          summer months the town’s daily population would swell to just under 10,000 guests who would spend
          the day promenading through the campgrounds and taking in the salubrious climate.
        </Paragraph>
        <Paragraph>
          It was during this heyday of the Gilded Age that Ira M. Cobe would become a regular
          visitor during his years in Boston. On one such trip he met and fell in love with Annie E.
          Watts of Belfast, who he would go on to marry in 1892. It was this romance that gave
          purchase to the dream that would twenty years later become Oak Hall.
        </Paragraph>
      </Article>
      <Img
        imgSrc={data.northportCampground.childImageSharp.fluid}
        imgAlt="Postcard: Norhtport Camp Ground, Maine"
        isWide
      />
    </Section>
    <Section>
      <Article>
        <H2>Bayside</H2>
        <Paragraph isLarge>
          Just north of Oak Hall lies the small summer colony of Bayside. Its 300 charming
          Victorian cottages hug the shore of Penobscot Bay for almost three miles.
        </Paragraph>
        <ParagraphPull orientation="RIGHT">
          <Img
            imgSrc={data.cottagesBook.childImageSharp.fluid}
            imgAlt="Book cover: If These Cottages Could
            Talk"
          />
          <Footnote>
            Available from the Bayside Historical Preservation Society, “If These Cottages Could
            Talk’ contains a near complete history of Northport Campgrounds, and includes a chapter
            on Oak Hall.
          </Footnote>
        </ParagraphPull>
        <Paragraph>
          Bayside began as a Methodist Church campground in 1849. That year, a committee from the
          Maine Conference of Churches purchased twenty-five acres where churchmen and farmers could
          pitch their tents among the trees for one week and worship God with sermons, songs, and
          old-fashioned religious fervor. In 1873, the Northport Wesleyan Grove Camp Meeting
          Association incorporated and purchased thirteen more acres.
        </Paragraph>
        <Paragraph>
          Church societies from local towns built large Society Cottages – wooden, barn-like
          structures with double front doors, big front rooms, tiny upstairs bedrooms, rows of small
          cupboards along dining room walls to hold each family’s tableware, and a large signboard
          above the front door bearing the name of the community from which the church members came:
          Belfast, Brewer, Orrington, Thomaston, South Thomaston, Rockland, Rockport, Camden,
          Warren, Monroe, Union, Morrell, Bucksport, Orono, Bangor, and many more.
        </Paragraph>
        <ParagraphPull orientation="LEFT">
          <Img
            imgSrc={data.bayViewPark.childImageSharp.fluid}
            imgAlt="Postcard: Entrance to Bay View Park, Northport, Maine Camp Ground"
          />
          <Footnote>Postcard: Entrance to Bay View Park, Northport, Me. Camp Ground</Footnote>
        </ParagraphPull>
        <Paragraph>
          Small private cottages with fancy carved gingerbread trim and quaint names were going up
          everywhere. They are similar to the small family cottages built around the Methodist
          campground at Oak Bluffs on Martha’s Vineyard and those at Chautauqua, New York, which was
          also started by Methodists.
        </Paragraph>
        <Paragraph>
          A fine wharf and the Wesleyan Hotel attracted steamers from Boston, Rockland, and Bangor,
          bringing folks who were changing the religious campground into a summer resort. Roads,
          sewers, and water came to the little community, along with two grocery stores, a police
          force, and a professional photographer. A large auditorium was built as a place for
          sermons, revivals and educational speakers.
        </Paragraph>
        <ParagraphPull orientation="RIGHT">
          <Img
            imgSrc={data.northportHotel.childImageSharp.fluid}
            imgAlt="Postcard: Northport Hotel, Northport, Maine (August 9, 1906)"
          />
          <Footnote>Postcard: Northport Hotel, Northport, Me. (August 9, 1906)</Footnote>
        </ParagraphPull>
        <Paragraph>
          In the Gay Nineties, the Wesleyan Hotel burned and the larger Northport Hotel took its
          place. The Bangor Band gave weekly concerts in front of the hotel and there was dancing
          inside. There were clam bakes, picnics, hay rides, firemen’s musters, baseball games, and
          excursions.
        </Paragraph>
        <Paragraph>
          In 1915, Ira Cobe led the effort to incorporate the Northport Village Corporation to
          administer community affairs and distribute tax funds. In 1916, Ira Cobe was the prime
          mover in the establishment of the Northport Golf Club, a nine-hole course, and the
          Northport Country Club, where the clubhouse sponsored musical performances and dances
          throughout the summer.
        </Paragraph>
      </Article>
    </Section>
    <Section>
      <Img
        imgSrc={data.templeHeights.childImageSharp.fluid}
        imgAlt="Postcard: Temple Heights"
        isWide
      />
      <Article>
        <H2>Temple Heights</H2>
        <Paragraph isLarge>
          South of Bayside, high above the Shore Road, perches the Spiritualist camp called Temple
          Heights.
        </Paragraph>
        <Paragraph>
          In 1883, a company of Maine Spiritualists formed the Temple Heights Association to bring
          together spiritualists, clairvoyants, and mediums from all over the country. A lodge and
          individual cottages were built on the hill above the beach, and finally a wharf and large
          auditorium were added.
        </Paragraph>
        <Paragraph>
          Spiritualism was a popular pastime during the first half of the twentieth century. Today,
          the auditorium is still used for Sunday afternoon and weeknight church services; and the
          lodge is used for classes, private readings, and message circles. Each summer, well-known
          mediums come from many parts of the country to spend a week exploring the science of
          parapsychology. Temple Heights is another surprising vestige of the past on the coast of
          Maine.
        </Paragraph>
      </Article>
    </Section>
  </Layout>
);

export const query = graphql`
  query {
    northportDocks: file(absolutePath: {regex: "/\\/northport\\/northport-docks\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    northportCampground: file(absolutePath: {regex: "/\\/northport\\/northport-campground\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cottagesBook: file(absolutePath: {regex: "/\\/northport\\/cottages-book\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 840) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bayViewPark: file(absolutePath: {regex: "/\\/northport\\/bay-view-park\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 840) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    northportHotel: file(absolutePath: {regex: "/\\/northport\\/northport-hotel\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 840) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    templeHeights: file(absolutePath: {regex: "/\\/northport\\/temple-heights\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default NorthportPage;
